




















































































































































































































































































































































.review-link-widget{
  .vue-tel-input{
    background: #fff;
    border-radius: 5px;
    border: 0;
    input{
      font-size: 1.25rem;
      border-radius: 0 5px 5px 0;
    }
  }
}
